<template>
    <header>
        <div
            class="flex flex-col lg:flex-row justify-between py-7 px-8 lg:px-5"
            :class="{ 'hidden lg:flex !justify-center': route.meta.layout === 'account' }"
        >
            <div
                v-if="route.meta.layout !== 'account'"
                class="flex flex-col justify-end hidden lg:w-48 lg:block"
            >
                <a
                    v-if="route.path !== '/'"
                    class="no-underline lg:w-48 flex items-center cursor-pointer mb-2 lg:mb-0"
                    @click="goHome"
                >
                    <icon-arrow-left
                        filled
                        class="h-6 w-auto mr-5 mt-0.5"
                    />
                    {{ $t("layouts.header.back") }}
                </a>
            </div>
            <layout-logo />
            <!-- Empty div to keep the layout consistent -->
            <div
                v-if="route.meta.layout !== 'account'"
                class="hidden lg:w-48 lg:block"
            />
        </div>

        <div
            class="lg:hidden"
            :class="{ block: route.meta.layout === 'account', hidden: route.meta.layout !== 'account' }"
        >
            <account-sidebar-toggle />
        </div>
    </header>
</template>

<script setup lang="ts">
import IconArrowLeft from "~/public/icons/arrow_left.svg";

const localePath = useLocalePath();
const route = useRoute();
const { isAuthenticated, logout } = useSanctumAuth();

const goHome = async () => {
    if (route.meta.layout === "cms") {
        await navigateTo(localePath("/portal/card"));
        return;
    }

    if (isAuthenticated.value) {
        await logout();
    }

    await navigateTo(localePath("/"));
};
</script>
